import React from 'react'
import Finedge from "../images/Finedge.svg"
import lightMode from "../images/lightMode.svg"
import darkMode from "../images/darkMode.svg"
import { ThemeContext } from './ThemeContext';


function NavMenu() {

    const { theme, setTheme } = React.useContext(ThemeContext);
    const [imageIcon,setImageIcon] = React.useState(lightMode)

    function switchOn(){
        setTheme(theme === 'dark' ? 'light' : 'dark')  
        setImageIcon(theme === 'dark' ? lightMode : darkMode) 
    }

    setTimeout(()=>{
      setImageIcon(localStorage.getItem("color-theme")==='dark'? darkMode: lightMode)
    },500)

  


  return (
    <div className="flex justify-between">
        <img src={Finedge} alt="finedge"/>

      
        <div className="flex">
        <div className="mr-7">
        <img onClick={switchOn} src={imageIcon} alt="darkMode" />
        </div>
        <div className="navMenu text-black dark:text-white mt-2">
            <a href="#core-banking" className="mr-7">Core Banking</a>
            <a href="#online-banking">Online Banking</a>
        </div>
        </div>

    </div>
  )
}

export default NavMenu